<template>
     <div ref="valiGroup" class="vali-input-group" role="valiGroup">
          <slot></slot>
     </div>
</template>

<script>
import { provide, toRefs, reactive } from 'vue'
export default {
     props: {
          validateForm: {
               type: Object
          }
     },
     setup(props, { emit }) {
          const vali_cb_group = []

          const react_provi = reactive({
               validateForm: props.validateForm,
               onVali: (fn) => {
                    vali_cb_group.push(fn)
               }
          })

          const startVali = (cb) => {
               let validate_error = []
               for (let fx in vali_cb_group) {
                    let { value, label, key } = vali_cb_group[fx]()
                    if (!value) {
                         validate_error.push({ value, label, key: key || null });
                    }
               }

               if (validate_error.length) {
                    cb(false, validate_error)
               } else {
                    cb(true, 'all')
               }

          }

          provide('vali_input', react_provi)


          return {
               ...toRefs(react_provi),
               startVali
          }
     }
}
</script>