<template>
     <div class="validate_input flex_row_between_center" :class="{ valiErr, isOutStyle: outStyle }">
          <input :type="type" @input="handleInput" @blur="handleBlur" @keydown="handleKeydown" @keyup="handleKeyUp"
               v-model="nativeValue" @focus="handleFocus" :key="key" :placeholder="placeholder" ref="input">
          <div class="validate_count" v-if="showCount && maxlength">{{ nativeValue.length }}/{{ maxlength }}</div>
          <div class="val_err_msg" v-show="valiErr" :class="{ [errPos]: errPos }">{{ errMsg }}</div>
     </div>
</template>

<script>
import { ref, watch, inject, nextTick, getCurrentInstance, onMounted } from 'vue'
export default {
     props: {
          modelValue: {
               default: ''
          },
          maxlength: {
               type: [Number, String],
          },

          definekey: {
               type: [Number, String],
          },

          showCount: {
               type: Boolean,
               default: false
          },
          type: {
               type: String,
               default: 'text'
          },

          validate: {
               type: Function
          },

          errPos: {
               type: String,
               default: 'pos_bottom'
          },

          mode: {
               type: String
          },

          max: {
               type: Number,
               default: 9999999999
          },

          min: {
               type: Number,
               default: -9999999999
          },

          placeholder: {
               type: String
          },

          label: {
               type: String
          },

          outStyle: {
               type: Boolean,
               default: true
          },

          related: {
               type: Function
          }
     },

     emits: ['update:modelValue', 'input', 'change', 'focus', 'blur', 'clear', 'mouseleave', 'mouseenter', 'keydown'],

     setup(props, ctx) {
          const nativeValue = ref('')
          const errMsg = ref('')
          const valiErr = ref(false)
          const { proxy } = getCurrentInstance()

          const validate_group = inject('vali_input')
          validate_group && validate_group.onVali(() => {
               if (props.label) {
                    const current_validate_type = validate_group.validateForm[props.label]
                    let returnValue = {
                         label: props.label,
                         value: validateValue(current_validate_type)
                    }
                    if (props.definekey) returnValue.key = props.definekey
                    return returnValue
               } else {
                    return {
                         label: 'none',
                         value: true
                    }
               }
          })

          const validateValue = (val_obj) => {
               let final_res = true
               for (let keyType in val_obj) {
                    if (keyType == 'required') {

                         const valueType = (typeof nativeValue.value)
                         if ((valueType == 'string' && nativeValue.value.toString().trim()) || (valueType == 'number' && !isNaN(nativeValue.value))) {
                              valiErr.value = false
                              final_res = true
                         } else {
                              valiErr.value = true
                              errMsg.value = val_obj.errMsg
                              final_res = false
                              break
                         }
                    }

                    if (keyType == 'checkMobile') {
                         if (proxy.$checkPhone(nativeValue.value) != true) {
                              valiErr.value = true
                              errMsg.value = val_obj.checkMobile_errMsg
                              final_res = false
                              break
                         } else {
                              valiErr.value = false
                              final_res = true

                         }
                    }

                    if (keyType == 'notZero') {
                         if (nativeValue.value <= 0) {
                              valiErr.value = true
                              errMsg.value = val_obj.notZero_msg
                              final_res = false
                              break
                         } else {
                              valiErr.value = false
                              final_res = true
                         }
                    }

                    if (keyType == 'related') {
                         if (!props.related()) {
                              valiErr.value = true
                              errMsg.value = val_obj.related_msg
                              final_res = false
                              break
                         } else {

                              valiErr.value = false
                              final_res = true
                         }
                    }

               }
               return final_res
          }


          const handleInput = event => {
               let { value } = event.target
               if (props.maxlength) {
                    if (value.toString().length > props.maxlength) {
                         const sliceIndex = props.maxlength
                         value = value.slice(0, Number(sliceIndex))
                    }
               }
               if (props.type == 'number' && props.mode !== 'tel') {
                    let sortArr = [parseFloat(props.min), parseFloat(value), parseFloat(props.max)].sort((a, b) => b - a)
                    value = sortArr[1]
               }
               switch (props.mode) {
                    case 'tel': {
                         if (value.toString().length > 11) {
                              value = value.toString().slice(0, 11)
                         }
                         break
                    }
                    case 'float': {
                         let setPart = value.toString().split('.')
                         let int = setPart[0], decimal = setPart[1]
                         if (decimal && decimal.length > 2) {
                              value = int + '.' + decimal.slice(0, 2)
                         }
                         break
                    }

                    case 'int': {
                         let setPart = value.toString().split('.')
                         let int = setPart[0], decimal = setPart[1]
                         if (decimal) {
                              value = int
                         }
                         break
                    }
               }
               ctx.emit('update:modelValue', value)
               ctx.emit('input', value)
               nativeValue.value = value
               if (valiErr.value) {
                    valiErr.value = false
               }
          }


          const handleFocus = event => {
               ctx.emit('focus', event)
          }

          const handleBlur = event => {
               ctx.emit('blur', event)
          }

          const handleKeydown = e => {

               if (props.type == 'number') {
                    if (e.keyCode == 69) {
                         e.preventDefault()
                         return false
                    }
                    switch (props.mode) {
                         case 'tel': {
                              if (e.keyCode == 190 || e.keyCode == 189) {
                                   e.preventDefault()
                                   return false
                              }
                              break
                         }

                    }
               }
               ctx.emit('keydown', e)
          }

          const handleKeyUp = (e) => {
               ctx.emit('keyup', e)
          }




          watch(() => props.modelValue, () => {
               nativeValue.value = props.modelValue
          })

          onMounted(() => {
               nativeValue.value = props.modelValue
          })

          return {
               handleFocus,
               handleKeydown,
               handleBlur,
               handleInput,
               nativeValue,
               errMsg,
               valiErr,
               handleKeyUp
          }
     }
}
</script>

<style lang="scss">
$input-height: 30px;

/*添加css样式*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
     -webkit-appearance: none;
}

input[type="number"] {
     -moz-appearance: textfield;
}

.validate_input {

     position: relative;
     height: 100%;

     &.valiErr {
          border: 1px solid #F30606 !important;
     }

     &.isOutStyle {
          border: 1px solid #c9c9c9;
          padding: 0 10px;
          border-radius: 5px;
     }

     input {
          border: none;
          outline: none;
          height: 99%;
          color: #999999;
          width: 99%;
     }

     .validate_count {
          color: #999;
          font-size: 12px;
          margin-left: 10px;
     }

     .val_err_msg {
          position: absolute;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #F30606;
          background: #fff;
          z-index: 99;
          padding: 1px;
          white-space: nowrap;
     }

     .pos_right {
          right: -20px;
          bottom: calc(var($input-height)/2)
     }

     .pos_bottom {
          bottom: -20px;
          left: 2px;
     }

     .pos_top {
          top: -18px;
          left: 2px;
     }
}
</style>